import useSWRImmutable from 'swr/immutable';

import { type ModelsGamePackPrice } from '@lp-lib/api-service-client/public';

import { apiService } from '../../services/api-service';
import { type GamePack } from '../../types/game';
import { StripeCustomCheckoutProvider } from '../Payment/StripeCustomCheckoutProvider';

export function OTPCheckoutCustomProvider(props: {
  pack: GamePack;
  price: ModelsGamePackPrice;
  returnUrl: string;
  children: React.ReactNode;
}) {
  const { pack, price, returnUrl, children } = props;

  const { data, isLoading } = useSWRImmutable(
    ['/game-pack/create-checkout-session', pack.id, price.id],
    async () => {
      const resp = await apiService.gamePack.createGamePackCheckoutSession(
        pack.id,
        {
          priceId: price.id,
          uiMode: 'custom',
          returnUrl,
        }
      );
      return resp.data;
    }
  );

  if (isLoading || !data) return null;

  return (
    <StripeCustomCheckoutProvider clientSecret={data.clientSecret}>
      {children}
    </StripeCustomCheckoutProvider>
  );
}
